@import "../../assets/styles/resets";
@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";
@import "../../assets/styles/reuseable";

body {
  background-color: $background;
  width: 100%;
}

.Campaigns {
  position: relative;
  background-image: url("../../assets/images/IMG-20240312-WA0012.jpg");
//  background-repeat: no-repeat;
  background-size: fill;
  width: 100%;
  height: 60vh;

  .overlay {
    position: absolute;
    width: 100%;
    height: 60vh;
    background: #1b1b1b;
    opacity: 50%;
  }
  .content {
    @include flex(column, 1rem, space-between);
    height: 100%;
    width: 100%;
    position: absolute;
    text-align: center;

    h1 {
      color: #fff;
      @include League(700);
    }
  }
}
