@import "../../assets/styles/resets";
@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";
@import "../../assets/styles/reuseable";

body {
  background-color: $background;
  width: 100%;
}

.TeamsCon {
  position: relative;
  background-image: url("../../assets/images/askGhanaa-about.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;

  .overlay {
    position: absolute;
    width: 100%;
    height: 60vh;
    background: #1b1b1b;
    opacity: 50%;
  }
  .content {
    @include flex(column, 1rem, space-between);
    height: 100%;
    width: 100%;
    position: absolute;
    text-align: center;

    h1 {
      color: #fff;
      @include League(700);
    }
  }
}

.TeamsWrap {
  @include flex(row, '', center);
  align-items:center;
  padding: 1rem;
  margin-top: 2rem;

  .teamwraplist{
    width: 100%;
    @include flex(row wrap, 2rem, center);
    align-items:center;
  }
}

@media screen and (max-width: 768px) {
  .TeamsWrap {
    @include flex(column, 2rem, space-between);
  }
}
