@import "../../assets/styles/resets";
@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";
@import "../../assets/styles/reuseable";

body {
  background-color: $background;
  width: 100%;
}



.w3-row-padding img {margin-bottom: 9px}


@media screen and (max-width: 768px) {}