// Text Colors
$textWhite: #fafafa;
$textBlack: #222831;

// Brand Colors
$brandPrimary: #dc2f2f;
$brandSecondary: hwb(261 8% 13%);
$brandPrimaryLight1: #fd5959;
$brandSecondaryLight2:#AA81F3;
$brandGreen: rgb(5, 102, 5);

//Button Colors


//Other Colors
$background: #fafafa;