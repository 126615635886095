@import "../../assets/styles/resets";
@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";
@import "../../assets/styles/reuseable";

body {
    background-color: $background;
    width: 100%;
  }

  
.PartnersCon {
    position: relative;
    background-image: url("../../assets/images/askGhanaa-about.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
  
    .overlay {
      position: absolute;
      width: 100%;
      height: 60vh;
      background: #1b1b1b;
      opacity: 50%;
    }
    .content {
      @include flex(column, 1rem, space-between);
      height: 100%;
      width: 100%;
      position: absolute;
      text-align: center;
  
      h1 {
        color: #fff;
        @include League(700);
      }
    }
  }

  .PartnersWrap{
    padding: 5rem;
    p{
        @include League(400);
        font-size: 1.7rem;
        padding-bottom: 1rem;
    }
  }


  @media screen and (max-width: 768px) {
    .PartnersWrap{
        padding: 1rem;
      }
    .partnessWrapSecond {
      margin-top: 45rem;
    }
  }