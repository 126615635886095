@import "../../assets/styles/resets";
@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";
@import "../../assets/styles/reuseable";

body {
  background-color: $background;
}

.donations {
  position: relative;
  background-image: url("../../assets/images/askGhana-donation.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;

  .overlay {
    position: absolute;
    width: 100%;
    height: 60vh;
    background: #1b1b1b;
    opacity: 50%;
  }
  .content {
    @include flex(column, 1rem, space-between);
    height: 100%;
    width: 100%;
    position: absolute;
    text-align: center;

    h1 {
      @include League(700);
      font-size: 3rem;
      color: #fff;
    }
  }
}

.paymentWrapper{
  @include flex(column,1rem, space-between);
  align-items: center;
  
  h3{
    width: 30%;
    font-size:1.2rem;
    text-align: center;
    @include League(600);
  }

  form{
    @include flex(column,1rem,space-between);

    input{
      padding: .4rem 1rem;
      @include League(600);
      outline: none;
    }

    button{
      background: $brandPrimary;
      color: #fff;
      @include League(500);
      border: none;
      outline: none;
      padding: .4rem 2rem;
      border-radius: .4rem;
    }
  }
}
