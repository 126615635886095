@import "../../assets/styles/resets";
@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";
@import "../../assets/styles/reuseable";

body {
  background-color: $background;
  width: 100%;
}

.What_We_Do {
    position: relative;
    background-image: url("../../assets/images/askGhanaa-about.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
  
    .overlay {
      position: absolute;
      width: 100%;
      height: 60vh;
      background: #1b1b1b;
      opacity: 50%;
    }
    .content {
      @include flex(column, 1rem, space-between);
      height: 100%;
      width: 100%;
      position: absolute;
      text-align: center;
  
      h1 {
        color: #fff;
        @include League(700);
      }
    }
  }
  .programCards {
    @include flex(row wrap, 1rem, space-between);
    padding: 0 8rem;
    margin-top: 2rem;
  }

  @media screen and (max-width: 768px) {
    .programCards {
        @include flex(column, 2rem, space-between);
        padding: 0 1rem;
      }
  }