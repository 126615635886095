@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/*
                        IMPORTANT READ BEFORE USING MIXINS
        The $weight is used to set the weight of the font you can use any number from 100 to 900.
        The $style is used to set the font style of the text you want eg(italic or normal).
*/


@mixin Merriweather($weight) {
    font-family: "Merriweather Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: $weight;
}

@mixin Poppins($weight, $style) {
    font-family: "Poppins", sans-serif;
    font-weight: $weight;
    font-style: $style;
}

@mixin Montserrat($weight) {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: $weight;
    font-style: normal;
}

@mixin League($weight) {
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-weight: $weight;
    font-style: normal;
}



