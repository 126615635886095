@import "../../assets/styles/resets";
@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";
@import "../../assets/styles/reuseable";

body {
  background-color: $background;
  width: 100%;
}

.About {
  position: relative;
  background-image: url("../../assets/images/askGhanaa-about.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;

  .overlay {
    position: absolute;
    width: 100%;
    height: 60vh;
    background: #1b1b1b;
    opacity: 50%;
  }
  .content {
    @include flex(column, 1rem, space-between);
    height: 100%;
    width: 100%;
    position: absolute;
    text-align: center;

    h1 {
      color: #fff;
      @include League(700);
    }
  }
}

.aboutCon {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  padding: 0 6rem;

  h1 {
    @include League(700);
  }

  p {
    margin-top: 1rem;
    @include Poppins(300, normal);
    text-align: left;

    b{
        font-weight: 700;
    }

    ul{
        margin-top: 2rem;

        p{
            font-weight: 700;
            margin-bottom: .5rem;
        }
    }
  }
}

@media screen and (max-width: 768px) {
  .About {
    height: 45vh;

    .overlay {
      height: 45vh;
    }
  }

  .aboutCon {
    padding: 0 1rem;

    ul{
        padding: 0 1rem;
    }
  }
}
